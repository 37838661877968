// Function to handle the progress animation
function animateProgress(progressElement) {
  // Reapply the transition before starting the animation
  progressElement.style.transition = 'transform 4s linear, border-color 0.5s';

  // Change the border color to the CSS variable and rotate the border from left to right
  progressElement.style.borderColor = 'var(--wp--preset--color--brand)'; // Border becomes the color of the CSS variable
  progressElement.style.transform = 'rotate(0deg)'; // Rotate from left to right

  // Add the .active class to the wrapper after a 1-second delay
  setTimeout(() => {
    const progressBackground = document.querySelector('.sales-progression');
    if (progressBackground) {
      progressBackground.classList.add('active');
    }
  }, 4000); // add active class delay
}

// Main function to trigger the animation when #section-world is visible
function startProgressAnimation() {
  const progressElement = document.querySelector('.progress');
  const sectionWorld = document.getElementById('section-world');

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setTimeout(() => {
          animateProgress(progressElement);
        }, 1000); // delay before starting the animation
        observer.unobserve(sectionWorld); // Stop observing after the animation starts
      }
    });
  }, { threshold: 0.1 });

  observer.observe(sectionWorld);
}

// Call the function on page load
window.onload = startProgressAnimation;
