const paragraph = document.getElementById('animatedParagraph');
const words = paragraph.textContent.split(' ');
paragraph.innerHTML = words.map(word => `<span>${word}</span>`).join(' ');

const spans = paragraph.querySelectorAll('span');
let currentIndex = 0;

function animateNextWord() {
  spans[currentIndex].style.color = `var(--wp--preset--color--brand)`;
  currentIndex++;
  setTimeout(animateNextWord, 200);
}

const sectionManifesto = document.getElementById('section-manifesto');

const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      setTimeout(animateNextWord, 1000); // delay before starting the animation
      observer.unobserve(sectionManifesto);
    }
  });
}, { threshold: 0.1 });

observer.observe(sectionManifesto);
